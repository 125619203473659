<template>
  <v-container tag="section">
    <validation-observer v-slot="{ valid }">
      <material-wizard
        title="Importar INDGER"
        subtitle="Preencha o formulário e faça o upload dos arquivos da nova INDGER."
        v-model="tab"
        :available-steps="availableSteps"
        :tabs="tabs"
        class="mx-auto"
        @click:next="next(valid)"
        @click:prev="tab--"
        :loading="loading"
        :disabledBtnFinalizar="disabledBtnFinalizar"
      >
        <v-tab-item class="pb-1">
          <form @submit.prevent="next(valid)">
            <first-tab-content @import:updated="handleTabContentUpdated" />
          </form>
        </v-tab-item>
        <v-tab-item class="pb-1">
          <second-tab-content
            @import:updated="handleTabContentUpdated"
            @mapeamento:atualizado="handleTabContentUpdated"
            @mapeamento:apagar-arquivo="handleTabContentArquivoExcluido"
          />
        </v-tab-item>
      </material-wizard>
    </validation-observer>
  </v-container>
</template>

<script>
import ImportacoesIndgerService from '@/services/ImportacoesIndgerService';
import tourMixins from '@/mixins/tourMixins';

export default {
  name: "ImportacoesIndgerAdd",
  mixins: [tourMixins],
  components: {
    MaterialWizard: () => import('@/components/base/MaterialWizard'),
    FirstTabContent: () => import('@/components/administracao/importacoes-indger/tabs/FirstTabContent.vue'),
    SecondTabContent: () => import('@/components/administracao/importacoes-indger/tabs/SecondTabContent.vue')
  },
  data: () => ({
    tab: 0,
    tabs: ['Informações básicas', 'Upload de arquivos'],
    loading: false,
    importacao: {
      titulo: null,
      descricao: null,
      companyId: null,
      data: null,
      mes: null,
      indgerVersion: null,
      encoding: 'UTF-8',
      mapeamentos: {},
      qtdEntidades: 0,
      numeroEntidadesAusentes: 0,
      numeroArquivos: 0,
      numeroArquivosDados: 0,
      numeroMapeados: 0,
      numeroNaoMapeados: 0
    },
    arrTourPrimeiraTab: [
      {
        element: '#fs-titulo-importacao-indger',
        intro: 'Aqui você deverá inserir um título para sua importação.',
        scrollTo: 'tooltip',
        position: 'bottom'
      },
      {
        element: '#fs-descricao-importacao-indger',
        intro: 'Aqui você poderá inserir uma descrição para sua importação.',
        scrollTo: 'tooltip',
        position: 'bottom'
      },
      {
        element: '#fs-empresa-importacao-indger',
        intro:
          'Aqui você deverá selecionar a empresa alvo que receberá a importação.',
        scrollTo: 'tooltip',
        position: 'bottom'
      },
      {
        element: '#fs-mes-dados-importacao-indger',
        intro: 'Aqui você deverá informar o mês/ano de referência dos dados.',
        scrollTo: 'tooltip',
        position: 'bottom'
      },
      {
        element: '#fs-versao-importacao-indger',
        intro:
          'Aqui você deverá informar a versão da INDGER que está sendo importada.',
        scrollTo: 'tooltip',
        position: 'bottom'
      }
    ],
    arrTourSegundaTab: [
      {
        element: '#fs-encoding-importacao-indger',
        intro:
          'Aqui você deverá selecionar a codificação dos arquivos que serão utilizados na importação.',
        scrollTo: 'tooltip',
        position: 'bottom'
      },
      {
        element: '#fs-upload-importacao-indger',
        intro:
          'Nessa seção deverá ser enviado os arquivos que irão compor a importação.',
        scrollTo: 'tooltip',
        position: 'top'
      }
    ]
  }),
  mounted() {
    this.iniciarTourPrimeiraTab();
  },
  computed: {
    availableSteps() {
      let steps = [0];

      if (this.tab1IsValid) {
        steps.push(0);
        steps.push(1);
      }

      if (this.tab2IsValid) {
        steps.push(2);
      }

      return steps;
    },
    tab1IsValid() {
      const { titulo, companyId, mes, indgerVersion } = this.importacao;

      return (
        titulo !== null &&
        companyId !== null &&
        mes !== null &&
        indgerVersion !== null &&
        indgerVersion >= 0
      );
    },
    tab2IsValid() {
      const { qtdEntidades, numeroEntidadesAusentes, numeroArquivos, numeroMapeados } = this.importacao;

      return (
        this.tab1IsValid &&
        numeroArquivos > 0 &&
        numeroMapeados > 0 &&
        numeroEntidadesAusentes === 0 &&
        numeroArquivos === numeroMapeados &&
        qtdEntidades === numeroMapeados
      );
    },
    disabledBtnFinalizar() {
      if (this.tab === 0) return !this.tab1IsValid;
      if (this.tab === 1) return !this.tab2IsValid;

      return true;
    }
  },
  methods: {
    async next(valid) {
      if (!valid) return;

      if (this.tab === 0 && !this.tab1IsValid) {
        return;
      }

      if (this.tab === 1) {
        if (!this.tab2IsValid) {
          return;
        }

        const sucesso = await this.cadastraNovaImportacao();
        if (!sucesso) {
          return;
        }

        this.$router.push({
          name: 'Importações INDGER'
        });
      } else {
        this.tab++;
      }
    },
    handleTabContentUpdated(event) {
      this.importacao = Object.assign(this.importacao, event);
    },
    handleTabContentArquivoExcluido(event) {
      let importacao = this.importacao;

      if (importacao.numeroArquivos > 0) importacao.numeroArquivos -= 1;

      /* Verifica se o arquivo a ser excluido foi mapeado */
      if (event.id in importacao.mapeamentos) {
        this.$delete(importacao.mapeamentos, event.id);
        if (importacao.numeroMapeados > 0) importacao.numeroMapeados -= 1;
      } else {
        if (importacao.numeroNaoMapeados > 0) importacao.numeroNaoMapeados -= 1;
      }

      this.importacao = importacao;
    },
    async cadastraNovaImportacao() {
      let sucesso = false;

      if (this.importacao.numeroArquivosDados === 0) {
        this.$toast.error('Por favor, enviar pelo menos 1 arquivo.', '', 
          { position: 'topRight' }
        );
        return sucesso;
      }

      this.loading = true;

      try {
        sucesso = await ImportacoesIndgerService.save(this.importacao);

        this.$toast.success('Importação de INDGER cadastrada com sucesso.', '', {
          position: 'topRight'
        });
      } catch (error) {
        this.$toast.error('Ocorreu um erro ao cadastrar a importação de INDGER.', '',
          { position: 'topRight' }
        );
        console.error(error);
      } finally {
        this.loading = false;
      }

      return sucesso;
    },
    iniciarTourPrimeiraTab() {
      this.doneLabel = 'Próximo';
      this.desativarTourAoSair = false;

      let onBeforeExitFunction = {
        func: () => {
          this.tab = 1;
          this.initTourSegundaTab();
        }
      };

      this.iniciarTour(this.arrTourPrimeiraTab, [], [], [onBeforeExitFunction]);
    },
    initTourSegundaTab() {
      this.doneLabel = 'Finalizar';
      this.desativarTourAoSair = true;

      let onBeforeExitFunction = {
        func: () => (this.tab = 0)
      };

      this.iniciarTour(this.arrTourSegundaTab, [], [], [onBeforeExitFunction]);
    }
  }
};
</script>
